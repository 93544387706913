.c-modal__contentWrapper {
	padding: 0;
}

.c-modal--small .c-modal__contentWrapper {
	max-width: 460px;
}

.c-modal--medium .c-modal__contentWrapper {
	max-width: 500px;
}

.c-modal--large .c-modal__contentWrapper {
	max-width: 908px;
}

.c-modal__close:before {
	content: url("/images/icons/close-modal.svg");
}
