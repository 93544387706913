/* ==============================================================================================
 * RESPONSIVE INCLUDES
 * ============================================================================================== */

body:before {
	display: none;
}

@if (variable-exists(mq-breakpoints) and $enableResponsiveLayout) {
	@each $breakpointName, $breakpointValue in $mq-breakpoints {
		$breakpoint: #{$breakpointSeparator}#{$breakpointName};

		@include mq($from: $breakpointName) {

			// Objects
			// ---------------------------

			@include customWrapperSpacing($breakpoint, $breakpointName);
			@include layoutModifiers($breakpoint);
			@include wrapperModifiers($breakpoint);


			// Utilities
			// ---------------------------

			//@include alignUtility($breakpoint);
			//@include clearfixUtility($breakpoint);
			@include borderUtility($breakpoint);
			@include displayUtility($breakpoint);
			//@include overflowUtility($breakpoint);
			@include positionUtility($breakpoint);
			@include spacingUtility($breakpoint);
			@include typographyUtility($breakpoint);
			@include widthsUtility($breakpoint);
			@include flexUtility($breakpoint);


			body:before {
				content: "#{$breakpointName}";
				visibility: hidden;
			}
		}
	}
}
