/* ==============================================================================================
 * INPUTS
 * ============================================================================================== */

.c-input {
	position: relative;
}

.c-input--border {
	border-bottom: 4px solid getColor("red");
}

.c-input--box {
	border: 1px solid getColor("gray40");
	width: 100%;
	position: relative;
}

.c-input__icon {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	padding: 0;
	color: getColor("gray40");
	font-size: 14px;

	pointer-events: none;
	cursor: pointer;
}

.c-input--select {
	padding: 0;
	width: 100%;
}

.c-input--select .c-input__field {
	visibility: hidden;
}

.c-input--validationError .c-input__placeholder,
.c-input--validationError .c-input__field {
	color: getColor("red");
}

.c-input--validationError .c-input__field,
.c-input--validationError.c-input--box {
	border-color: getColor("red") !important;
}

.c-input__errorMessage {
	position: absolute;
	left: 0;
	bottom: -2em;
	font-size: 12px;
	color: getColor("red");
}

.c-input__field,
.c-input__placeholder {
	font-weight: 400;
	font-size: 0.75rem;
	letter-spacing: 0.08em;
	border: 0;
}

.c-input__field {
	flex-grow: 1;
	padding: 15px 20px;
	box-shadow: none;
}

.c-input__placeholder {
	position: absolute;
	color: getColor("gray40");
	background-color: getColor("white");
	border-radius: 0.5em;
	z-index: 1;
	padding: 0 5px;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	transition: 0.15s font-size, 0.15s top;
	pointer-events: none;
	text-transform: uppercase;
}

.c-input--textArea .c-input__placeholder {
	top: 20px;
}

.c-input.is-active .c-input__placeholder,
.c-input.is-filled .c-input__placeholder {
	top: 0;
	font-size: 8px;
}

.c-input--rightAligned {
	position: absolute;
	right: 0;
	margin-top: 3.5em;
	margin-right: 0.5em;
}


/* Input Required
 * ============================================================================================== */

.c-input--required::after {
	content: '*';
	color: getColor("red");
	position: absolute;
	right: 0.4em;
	top: 0.2em;
	font-size: 12px;
}


/* CHECKBOX
 * ============================================================================================== */

.c-input__box,
.c-input__radio {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	width: 14px;
	height: 14px;

	position: relative;
}

.c-input__box:before,
.c-input__radio:before {
	content: "";
	display: block;

	width: 14px;
	height: 14px;

	border: 1px solid getColor('black');
}

.c-input__box:after,
.c-input__radio:after {
	content: "";
	position: absolute;

	width: 8px;
	height: 8px;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: getColor('red');
	opacity: 0;

	transition: opacity .2s ease-in-out;
}

.c-input__box:checked:after,
.c-input__radio:checked:after {
	opacity: 1;
}

.c-input__box:checked ~ .c-input__label,
.c-input__radio:checked ~ .c-input__label {
	font-weight: 600;
}

.c-input__radio:before,
.c-input__radio:after {
	border-radius: 50%;
}

.c-input__label {
	font-size: 12px;
	letter-spacing: 0.08em;
	text-transform: uppercase;
}
