/* ==============================================================================================
 * SELECT
 * ============================================================================================== */

.c-select {
	display: inline-block;
	color: getColor("gray40");
	border: 1px solid getColor("gray40");
	background-color: transparent;
	transition: color .4s ease-in-out, background-color .4s ease-in-out;
	cursor: pointer;
	text-align: left;
	position: relative;
	width: 100%;

	&.is-open {
		color: getColor("gray");
		background-color: getColor("white");
	}
}

.c-select__menu {
	overflow: hidden;
	transition: max-height .4s ease-in-out;
}




/*  SELECT COMPONENT OVERRIDES
 * ============================================================================================== */

.c-select__dropdown {
	max-height: 15em;
	white-space: normal;
	overflow-x: hidden;
	display: none;
}

.c-select__button {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: getColor("gray40");
	background: getColor("white");
	padding: 15px 40px 15px 20px;
	width: 100%;
	text-align: left;
}

.c-select__button,
.c-select__item {
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.08em;
	font-family: $baseFontFamily;
}

.c-select__item {
	font-size: 0.75rem;
}


.c-select__button:focus,
.c-select__button:active {
	outline: none;
}

.c-select__button:after {
	@include iconMask("/images/icons/chevron-down.svg");

	content: '';
	position: absolute;
	right: 1.5em;
	font-weight: bold;
	font-size: 1em;
	background: getColor("gray40");
	cursor: pointer;
}

.ie .c-select__button:after {
	background-image: url("/images/icons/chevron-down.svg");
	background-color: transparent;
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
}

.c-select__list {
	margin: 0;
	list-style-type: none;
}

.c-select__groupLabel {
	font-weight: 700;
}

.c-select__search,
.c-select__item,
.c-select__groupLabel {
	padding: $globalSpacingXxs 20px;
	white-space: normal;
}

.c-select__select {
	display: none;
}

.c-select__searchInput {
	padding: 9px 0;
	width: 100%;
	border: 0;
	border-bottom: 1px solid getColor("gray20");
	font-family: $baseFontFamily;
	font-size: getFontSize(6);
	line-height: 1.25;
	color: getColor("gray40");
	border-radius: $baseBorderRadius;
	background: none;

	&:focus {
		outline: 0;
		border-bottom-color: getColor("gray40");
	}
}

.c-select__item:hover,
.c-select__item:focus {
	background: getColor("lightGray");
	outline: none;
}

.c-select__item.is-active {
	background: getColor("red");
	color: getColor("white");
}

.c-select__item--group {
	padding-left: ($globalSpacingSm * 2);
}

.c-select__dropdown.is-visible {
	display: block;
}

.c-select__dropdown.is-open {
	opacity: 1;
	transform: none;
	transition: transform 0.3s ease,
	opacity 0.3s ease;
}

.c-select__dropdown {
	z-index: 2;
	margin-top: -1px;
	padding: 0;
	background-color: getColor("white");
	border-radius: 0;
	position: absolute;
	border: 1px solid getColor("gray40");
	border-top: 0;
	left: -1px;
	right: -1px;
}



/* Disabled Select
 * --------------------------- */

.c-select[disabled] {
	border-color: getColor("gray20");
}

.c-select__button[disabled] {
	color: getColor("gray20");

	&:after {
		background: getColor("gray20");
	}
}



/* Select Scrollbar
 * --------------------------- */

.c-select__dropdown {
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: getColor("lightGray");
	}

	&::-webkit-scrollbar-thumb {
		background: getColor("gray40");
	}

	&::-webkit-scrollbar-thumb:hover {
		background: getColor("gray40");
	}
}
