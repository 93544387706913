/* ==============================================================================================
 * DRAG AND DROP FILE UPLOAD
 * ============================================================================================== */

.c-fileUpload--dragAndDrop {
	border: 4px dashed getColor("gray20");
}

.c-fileUpload--dragAndDrop.highlight {
	border-color: getColor("gray40");
}

.c-fileUpload--dragAndDrop .c-fileUpload__icon {
	margin-bottom: 1.5rem;
}

.c-fileUpload--dragAndDrop .c-fileUpload__fileListItem {
	justify-content: flex-start;
	border-bottom: 2px solid getColor("gray20");
}

.c-fileUpload--dragAndDrop .c-fileUpload__fileListItem:last-child {
	border-bottom: 0;
}

.c-fileUpload--dragAndDrop .c-fileUpload__itemDetail {
	flex-basis: 100px;
	flex-shrink: 0;
	color: getColor("gray40");
}

.c-fileUpload__addFile {
	color: getColor("red");
	font-weight: 600;
	cursor: pointer;
}

/* ==============================================================================================
 * SINGLE FILE UPLOAD
 * ============================================================================================== */

 .c-fileUpload--single {
	display: flex;
	height: 50px;
	border: 0;
}

.c-fileUpload--single .c-fileUpload__dropArea {
	width: 100%;
	flex-shrink: 0;
	min-height: 0;
}

.c-fileUpload--single .c-fileUpload__contentWrapper {
	display: flex;
	align-items: center;
	flex-direction: row;
	max-width: none;
	width: 100%;
	height: 100%;
	padding: 0;
	text-align: left;
}

.c-fileUpload--single .c-fileUpload__fileNamePlaceholder,
.c-fileUpload--single .c-fileUpload__uploadButton {
	height: 100%;
	font-size: 0.75rem;
	text-transform: uppercase;
}

.c-fileUpload--single .c-fileUpload__fileNamePlaceholder {
	flex-grow: 1;
	border: 1px solid getColor("gray20");
	border-right: 0;
	padding: 16px;
	color: getColor("gray40");
}

.c-fileUpload--single .c-fileUpload__uploadButton {
	border: 0;
	background-color: getColor("red");
	background-image: url("/images/button-arrow-up.svg");
	background-repeat: no-repeat;
	background-position: 80px 50%;
	color: #fff;
	padding: 16px 40px 16px 20px;
}

.c-fileUpload--single .c-fileUpload__fileListWrapper {
	width: 100%;
	min-height: 0;
	padding: 0 10px 0 16px;
}

.c-fileUpload--single .c-fileUpload__fileList {
	list-style: none;
	height: 100%;
}

.c-fileUpload--single .c-fileUpload__fileListItem {
	height: 100%;
	padding: 0;
	font-size: 0.75rem;
	text-transform: uppercase;
}

.c-fileUpload--single .c-fileUpload__fileName {
	overflow: hidden;
	display: block;
	width: 100%;
	margin-right: 10px;
	padding-top: 2px;
	white-space: nowrap;
}

.c-fileUpload--single.c-fileUpload.has-files .c-fileUpload__fileListWrapper {
	border: 1px solid getColor("gray20");
}

.c-fileUpload--single.highlight .c-fileUpload__fileNamePlaceholder {
	border-style: dashed;
}

/* ==============================================================================================
 * FALLBACK FILE UPLOAD
 * ============================================================================================== */

.c-fileUpload--fallback {
	border: 0;
}

.c-fileUpload--fallback {
	border: 0;
}

.c-fileUpload--fallback .c-fileUpload__input {
	position: relative;
	opacity: 1;
	visibility: visible;
	width: 100%;
	cursor: pointer;
}

.c-fileUpload--fallback .c-fileUpload__input::-ms-value {
	flex-grow: 1;
	border: 1px solid getColor("gray20");
	border-right: 0;
	padding: 16px;
	background-color: #fff;
	color: getColor("gray40");
}

.c-fileUpload--fallback .c-fileUpload__input::-ms-browse {
	border: 0;
	background-color: getColor("red");
	color: #fff;
	padding: 16px 20px;
}

/* ==============================================================================================
 * COMMON
 * ============================================================================================== */

.c-fileUpload__remove {
	display: block;
	width: 21px;
	height: 21px;
	padding: 4px;
	border: 1px solid getColor("gray40");
	background-image: url("/images/upload-remove.svg");
	background-repeat: no-repeat;
	background-position: center center;
}
