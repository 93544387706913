.c-stepper__label {
	position: absolute;
	top: -1px;
	left: -26px;
	width: 35px;
	height: 35px;
	font-size: 16px;
	border-radius: 50%;
	background-color: getColor("gray40");

	@include mq($from: "tabletH") {
		left: -54px;
		width: 43px;
		height: 43px;
		font-size: 20px;
	}

	@include mq($from: "laptop") {
		left: -12px;
		width: 55px;
		height: 55px;
		font-size: 24px;
	}
}

.c-stepper__title {
	cursor: pointer;
}

.c-stepper__step.is-active .c-stepper__label {
	background-color: getColor("red");
}

.c-stepper__step.is-active .c-stepper__title {
	cursor: unset;
}

.c-stepper__iconContainer {
	width: auto;
	position: absolute;
	top: 12px;
	right: 24px;
}

.c-stepper__step.is-active .c-stepper__iconContainer {
	display: none;
}
