/* ==============================================================================================
 * TEXT COLOR UTILITIES
 * ============================================================================================== */

._base {
	color: $baseColor !important;
}

@each $colorName, $colorHex in $colors {
	._#{$colorName} {
		color: $colorHex !important;
	}

	._hover\:#{$colorName}:hover {
		color: $colorHex !important;
	}
}
