/* ==============================================================================================
 * TABLE
 * ============================================================================================== */

.c-table {
	width: 100%;
	color: getColor("gray60");
	text-align: left;
}

.c-table__head {
	font-weight: 700;
	border-top: 2px solid getColor("gray20");
	border-bottom: 2px solid getColor("gray20");
}

.c-table__body {
	border-bottom: 2px solid getColor("gray20");
}

.c-table__cell {
	padding: $globalSpacingXs;
}

.c-table__cell:first-child {
	padding-left: 0;
}

.c-table__cell:last-child {
	padding-right: 0;
}

